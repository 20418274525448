const rAF = require("./rAF");

module.exports = function(){
  var ticking = false;

  function onScroll() {
    if (!ticking) {
      rAF(parallaxPictures);
    }
    ticking = true;
  }

  function parallaxPictures() {
    ticking = false;
    var wrappers = document.querySelectorAll('.parallax__container');
    
    for (var j = 0, wrapLen = wrappers.length; j < wrapLen; j++) {
      var el = wrappers[j],
          offset = el.getBoundingClientRect(),
          vpHeight = window.innerHeight,
          elements = el.querySelectorAll('.parallax__el'),
          topBound = vpHeight > offset.top - 150,
          bottomBound = offset.bottom > -150;

      if (topBound && bottomBound) {
        for (var i = 0, len = elements.length; i < len; i++) {
          var element = elements[i];
          setTransform(element, calculateParallax(offset.top, element.offsetHeight, i + 1));
        }
      } else {
        for (var i = 0, len = elements.length; i < len; i++) {
          setTransform(elements[i], 0);
        }
      }
    };
  }

  function calculateParallax(offsetTop, elHeight, index) {
    return (offsetTop - (elHeight / 2)) * (index / 7);
  }

  function setTransform(element, offsetY) {
    element.classList.add('parallax');
    element.style.transform = "translate3d(0," + offsetY + "px, 0)";
  }

  window.addEventListener('load', onScroll);
  window.addEventListener('resize', onScroll);
  window.addEventListener('scroll', onScroll);
}

const fullWidthParallaxTitles = document.querySelectorAll(".title-wrapper");
const fullWidthParallaxCopy = document.querySelectorAll(".copy-wrapper");
const observer = new IntersectionObserver(function(entries){
  entries.forEach(function(entry){
    if (entry.isIntersecting) {
      entry.target.classList.add("animate");
    } else {
      entry.target.classList.remove("animate");
    }
  });
});

// Tell the observer which elements to track
fullWidthParallaxTitles.forEach(function(title) {
  observer.observe(title);
});
fullWidthParallaxCopy.forEach(function(copy) {
  observer.observe(copy);
});