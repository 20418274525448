const Utils = require('./utils');
var setupNavigation = require("./navigation");
var setupParallax = require("./parallax");
var windowWidth = $(window).width();
var windowHeight = $(window).height();
var debounce = require("lodash.debounce");
var hero = document.querySelector(".hero--animated");

function adjustPosterSize() {
  var circles = $(".banner circle");
  var images = $(".banner .img");

  var circleR = 750;
  var circleX = window.innerWidth * 0.85;
  var circleY = 420;
  var imageX = window.innerWidth * 0.15;
  var imageY = "-200";
  var imageH = "120%";
  var imageW = "120%";

  if (window.innerWidth <= 750) {
    imageX = "-10%";
    imageH = "160%";
    imageW = "160%";
    imageY = "-300";
    circleR = 750;
    circleX = 730;
    circleY = 420;
  } else if (window.innerWidth <= 1200) {
    imageX = "-10%";
    circleX = 730;
  } else if (window.innerWidth <= 1600) {
    circleX = window.innerWidth;
  } else if (window.innerWidth <= 1900) {
    circleX = window.innerWidth * 0.9;
  }

  circles.attr("cx", circleX);
  circles.attr("cy", circleY);
  circles.attr("r", circleR);
  images.attr("x", imageX);
  images.attr("y", imageY);
  images.attr("height", imageH);
  images.attr("width", imageW);
}

setupParallax();
setupNavigation();

$(window).ready(function () {
  adjustPosterSize();
});
$(window).resize(function () {
  windowWidth = $(window).width();
  windowHeight = $(window).height();
  adjustPosterSize();
});

window.addEventListener("load", function () {
  hero.classList.add("active");
});


var imageRotators = Utils.queryArray("section.section--image-rotator");
if (imageRotators.length) {
  imageRotators.forEach(function(imageRotator){
    var switchersWrapper = imageRotator.querySelector(".section-switcher");
    var imagesWrapper = imageRotator.querySelector(".images");
    var switchers = Utils.queryArray(".switcher", switchersWrapper);
    var rotators = Utils.queryArray(".wrapper", imagesWrapper);

    var count = parseInt(imageRotator.querySelector(".images .wrapper.active").getAttribute("data-rotator-image"));

    var rotator = setInterval(function() {
      var currentSwitch = switchersWrapper.querySelector(".switcher.active");
      var prev = parseInt(currentSwitch.getAttribute("data-rotator-image"));
      count = prev < rotators.length - 1 ? prev + 1 : 0;
      var newSwitch = switchersWrapper.querySelector(".switcher[data-rotator-image='" + count + "']");

      updateRotatorSwitcher(currentSwitch, newSwitch);
      repositionImageRotator(imagesWrapper, count, prev);
    }, 5500);

    switchers.forEach(function(switcher) {
      switcher.addEventListener("click", function(e){
        e.preventDefault();
        var newSwitch = $(this).data("rotator-image");
        var newNumber = parseInt(newSwitch);
        var currentSwitch = switchersWrapper.querySelector(".switcher.active");
        var currentNumber = parseInt(currentSwitch.getAttribute("data-rotator-image"));
      
        if (newNumber != currentNumber) {
          updateRotatorSwitcher(currentSwitch, e.srcElement);
          repositionImageRotator(imagesWrapper, newNumber, currentNumber);
          clearInterval(rotator);
        }
      });
    });
  });
}

function updateRotatorSwitcher(currentSwitch, newSwitch) {
  currentSwitch.classList.remove("active");
  newSwitch.classList.add("active");
}

function repositionImageRotator (imagesWrapper, newNumber, currentNumber) {
  // Check the direction of change
  var num = currentNumber;
  if (newNumber > num) {
    // If up, then the previous change to inactive and new becomes active
    while (num != newNumber) {
      var oldImage = imagesWrapper.querySelector(".wrapper[data-rotator-image='" + num + "']");
      oldImage.classList.remove("active");
      oldImage.classList.add("inactive");
      num++;
    }
    var newImage = imagesWrapper.querySelector(".wrapper[data-rotator-image='" + newNumber + "']");
    newImage.classList.add("active");
  } else {
    // If down, then the previous lose active and new becomes active
    while (num != newNumber) {
      var oldImage = imagesWrapper.querySelector(".wrapper[data-rotator-image='" + num + "']");
      oldImage.classList.remove("active");
      oldImage.classList.remove("inactive");
      num--;
    }
    var newImage = imagesWrapper.querySelector(".wrapper[data-rotator-image='" + newNumber + "']");
    newImage.classList.add("active");
    newImage.classList.remove("inactive");
  }
}